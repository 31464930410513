<template>
    <div data-test="page" class="w-100 h-100 overflow-auto">
        <!-- ----------------------------- -->
        <!-- Custom Component - PageHeader -->
        <!-- ----------------------------- -->
        <PageHeader
            data-test="pageHeader"
            :pageSubTitle="titleObject.pageSubTitle"
            :pageTitle="titleObject.pageTitle"
            :iconName="titleObject.iconName"
            :startTour="startTour"
        />

        <!-- ------------------- -->
        <!-- Page Button Bar Top -->
        <!-- ------------------- -->
        <hr />
        <mdb-row class="m-0">
            <mdb-col data-test="buildCol" col="6" class="m-0">
                <h5 data-test="buildTitle" class="p-2">
                    Build GL Code (Second GLCode)
                    <span>
                        <small data-test="buildTitleSmall" class="muted" style="font-size: 70%"
                            >Build a GL Code based on one or many rules</small
                        >
                    </span>
                </h5>
                <mdb-card data-test="buildCard">
                    <mdb-card-header data-test="buildCardHeader">
                        <div class="input-group" id="name-select">
                            <select
                                data-test="buildSelect_carrierSelection"
                                v-model="carrierSelection"
                                class="form-control col-md-4"
                                id="carrier-select"
                            >
                                <option
                                    data-test="buildSelect_carrierSelectionOptions"
                                    v-for="option of carrierOptions"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>

                                >
                            </select>
                            <input
                                data-test="buildInput_glcode"
                                type="text"
                                class="form-control col-md-3"
                                v-model="glCode"
                                id="glcode"
                                placeholder="GL Code"
                            />
                            <input
                                data-test="buildInput_glCodeAlias"
                                type="text"
                                class="form-control col-md-3"
                                v-model="glCodeAlias"
                                id="glcodealias"
                                placeholder="GL Code Alias"
                            />
                            <span
                                data-test="build_addDescription"
                                class="adddesc small"
                                @click="addDescription = !addDescription"
                                >[Add Description]</span
                            >
                        </div>
                        <div class="input-group" v-if="addDescription">
                            <input
                                data-test="buildInput_addDescription"
                                type="text"
                                class="form-control col-md-12"
                                v-model="glCodeDesc"
                                id="glcodedesc"
                                placeholder="GL Code Description"
                            />
                        </div>
                        <hr />
                        <div id="any-select">
                            <template>
                                <div data-test="build_applyRadios" class="d-flex">
                                    <span>Apply </span>
                                    <mdb-input
                                        data-test="buildInput_applyRadioAll"
                                        type="radio"
                                        id="option1-1"
                                        name="applyRuleRadio"
                                        radioValue="and"
                                        v-model="applyRuleRadio"
                                        label="ALL of the following rules"
                                    />
                                    <mdb-input
                                        data-test="buildInput_applyRadioAny"
                                        type="radio"
                                        id="option1-2"
                                        name="applyRuleRadio"
                                        radioValue="or"
                                        v-model="applyRuleRadio"
                                        label="ANY of the following rules"
                                    />
                                </div>
                            </template>
                            <div class="input-group" id="create-rule">
                                <select
                                    data-test="buildSelect_groupOptionSelected"
                                    class="form-control col-md-4"
                                    @change="onChangeGroupOptions($event)"
                                    v-model="groupOptionSelected"
                                >
                                    <option data-test="buildSelect_groupOptionSelectedDefaultOption" value="0">
                                        Select Field
                                    </option>
                                    <optgroup
                                        data-test="buildSelect_groupOptionsSelectedOptgroup"
                                        v-for="(labels, idx) in optionGroupLabels"
                                        :key="idx"
                                        :label="labels.label"
                                    >
                                        <option
                                            data-test="buildSelect_groupOptionsSelectedOptions"
                                            v-for="(option, idx) in labels.optionGroups[0].options"
                                            :key="idx"
                                            :dropdown="option.dropdown"
                                            :data-type="option.datatype"
                                            :value="{
                                                value: option.value,
                                                text: option.text,
                                                datatype: option.datatype,
                                                dropdown: option.dropdown,
                                            }"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </optgroup>
                                </select>
                                <AccountDropdown
                                    id="selectAccounts"
                                    v-if="showMultiSelectDropdown && multiSelectDataKey == '1'"
                                    @update-account-selected="updateSelectedAccount"
                                    @update-selected-active="updateSelectedActive"
                                    :prepopulate="false"
                                    :userId="userInfo.usrId"
                                    :cmpId="userInfo.cmpID"
                                    :headers="headers"
                                    class="ml-1"
                                />
                                <MultiSelectDropdown
                                    data-test="build_multiSelectDropdown"
                                    v-if="showMultiSelectDropdown && multiSelectDataKey > '1'"
                                    v-on:sendSelectedOptionsToParent="collectSelectedOptions"
                                    :userInfo="userInfo"
                                    :headers="headers"
                                    :selectData="multiSelectData"
                                    :multiSelectOptions="multiSelectOptions"
                                    :key="multiSelectDataKey"
                                />
                                <select
                                    data-test="buildSelect_operatorSelection"
                                    class="form-control col-md-2"
                                    @change="onChangeOperators($event)"
                                    v-model="operatorSelectList.operatorSelection"
                                    v-if="showOperatorSelectList"
                                >
                                    <option data-test="buildSelect_operatorSelectionDefaultOption" value="0">
                                        Select Field
                                    </option>
                                    <option
                                        data-test="buildSelect_operatorSelectionOptions"
                                        v-for="(option, idx) in operatorSelectList[operatorSelectList.operatorToggler]"
                                        :key="idx"
                                        :value="{
                                            value: option.value,
                                            numoperand: option.numoperand,
                                            text: option.text,
                                        }"
                                    >
                                        {{ option.text }}
                                    </option>
                                </select>
                                <input
                                    data-test="buildInput_leftHandSide_string"
                                    v-if="showStringEquation"
                                    type="text"
                                    class="form-control col-md-4"
                                    v-model="leftHandSide"
                                />
                                <input
                                    data-test="buildInput_leftHandSide_number"
                                    v-if="showNumberEquation"
                                    type="number"
                                    class="form-control col-md-4"
                                    v-model="leftHandSide"
                                />
                                <div
                                    data-test="build_showDateEquationDatePickers"
                                    v-if="showDateEquation"
                                    class="col-md-6"
                                >
                                    <mdb-date-picker-2
                                        data-test="build_showDateEquation_datepickerLeftHandSide"
                                        size="sm"
                                        locale="en-US"
                                        class="bg-white"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        v-if="showDateEquation"
                                        v-model="leftHandSide"
                                    ></mdb-date-picker-2>
                                    <div
                                        data-test="build_showDateEquations_and"
                                        v-if="showRhs"
                                        class="muted"
                                        style="padding: 5px"
                                    >
                                        and
                                    </div>
                                    <mdb-date-picker-2
                                        data-test="build_showDateEquations_datepickerRightHandSide"
                                        size="sm"
                                        locale="en-US"
                                        class="bg-white"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        v-if="showDateRhs"
                                        v-model="rightHandSide"
                                    ></mdb-date-picker-2>
                                </div>
                                <div data-test="build_notShowDateEquation" v-if="!showDateEquation">
                                    <div
                                        data-test="build_notShowDateEquation_and"
                                        v-if="showRhs"
                                        class="muted"
                                        style="padding: 5px 5px 0 5px"
                                    >
                                        and
                                    </div>
                                    <div
                                        data-test="build_nowShowDateEquation_inputs"
                                        v-if="showRhs"
                                        class="col-md-4"
                                        style="clear: left; padding: 0"
                                    >
                                        <input
                                            data-test="build_notShowDateEquation_rightHandSide_string"
                                            v-if="showStringRhs"
                                            type="text"
                                            class="form-control"
                                            v-model="rightHandSide"
                                        />
                                        <input
                                            data-test="build_notShowDateEquation_rightHandSide_number"
                                            v-if="showNumberRhs"
                                            type="number"
                                            class="form-control"
                                            v-model="rightHandSide"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <mdb-btn data-test="build_addRuleBtn" @click.native="addRule()" variant="info">
                                    Add Rule
                                </mdb-btn>
                            </div>
                        </div>
                    </mdb-card-header>

                    <mdb-card-body data-test="buildCardBody" class="ruledisplay" style="margin-bottom: 0">
                        <mdb-row
                            data-test="build_addRuleDisplayRows"
                            v-for="(rule, index) of addRuleDisplay"
                            v-bind:key="rule.rule"
                            class="m-0"
                        >
                            <mdb-col data-test="build_addRuleDisplay_rule" lg="10" class="glruletext">
                                {{ rule.rule }}
                            </mdb-col>
                            <mdb-col lg="1" class="deleterule">
                                <mdb-btn
                                    data-test="build_addRuleDisplay_deleteRuleBtn"
                                    @click.native="deleteRule(rule.id, index)"
                                    class="btn btn-default"
                                    style="background-color: #ffffff"
                                >
                                    <mdb-icon icon="trash" title="Delete this Rule" />
                                </mdb-btn>
                            </mdb-col>
                        </mdb-row>
                    </mdb-card-body>
                    <mdb-card-footer data-test="buildCardFooter">
                        <mdb-btn
                            data-test="build_saveGLCodeBtn"
                            @click.native="saveGLCode()"
                            class="btn btn-info"
                            id="save"
                        >
                            Save GL Code
                        </mdb-btn>
                    </mdb-card-footer>
                </mdb-card>
            </mdb-col>
            <mdb-col data-test="glCodesCol" col="6" class="m-0" id="my-gl"
                ><h5 data-test="glCodesTitle" class="p-2">
                    My GL Codes
                    <span>
                        <small data-test="glCodesTitleSmall" class="muted" style="font-size: 70%">
                            Drag and drop to re-prioritize GL Codes, rules will be applied in the order they are listed
                            below.</small
                        >
                    </span>
                </h5>
                <mdb-card data-test="glCodesCard">
                    <mdb-card-body data-test="glCodesCardBody" class="dragRowParent" style="margin-bottom: 0">
                        <draggable
                            data-test="glCodes_draggable"
                            v-model="myGLCodesList"
                            group="people"
                            @start="drag = true"
                            @end="drag = false"
                        >
                            <div
                                data-test="glCodes_myGLCodesList"
                                v-for="element in myGLCodesList"
                                :key="element.glcode2_name"
                            >
                                <mdb-row class="dragRow">
                                    <mdb-col col="11" class="dragRowData">
                                        <mdb-icon icon="code-branch" class="muted" />
                                        <span data-test="glCodes_name" class="dragRowGLCode">
                                            {{ element.glcode2_name }}
                                        </span>
                                        <span>
                                            <span data-test="glCodes_aliasTitle" class="dragRowFieldLabel">Alias:</span>
                                            <span data-test="glCodes_alias" class="dragRowField">{{
                                                element.glcode2_alias
                                            }}</span>
                                            <span data-test="glCodes_carrierTitle" class="dragRowFieldLabel"
                                                >Carrier:</span
                                            >
                                            <span data-test="glCodes_carrier" class="dragRowField">{{
                                                carrierOptions[element.glcode2_car_id].abbr
                                            }}</span>
                                        </span>
                                        <div class="removeCol col-lg-1">
                                            <mdb-icon
                                                data-test="glCodes_deleteGLCode"
                                                @click.native="deleteGLCode(element)"
                                                icon="trash"
                                                class="muted"
                                                title="Delete ths GL Code"
                                            />
                                        </div>
                                    </mdb-col>
                                </mdb-row>
                            </div>
                        </draggable>
                    </mdb-card-body>
                    <mdb-card-footer
                        data-test="glCodesCardFooter"
                        style="background-color: #f9f9f9; padding: 10px; margin-bottom: 0"
                    >
                        <mdb-btn
                            data-test="glCodes_savePriorityBtn"
                            @click.native="savePriority()"
                            class="btn btn-info"
                        >
                            Save Priority
                        </mdb-btn>
                    </mdb-card-footer>
                </mdb-card>
            </mdb-col>
        </mdb-row>
        <GlCodingTour data-test="GlCodingTour" />
    </div>
</template>
<script>
import Axios from "axios";
import PageHeader from "@/components/PageHeader";
import MultiSelectDropdown from "@/components/MultiSelectDropdown";
import GlCodingTour from "@/components/GlCodingTour";
import { buttons, getTourDefaults } from "@/lib/shepherd-tour";
import draggable from "vuedraggable";
import {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbInput,
    mdbBtn,
    mdbCardBody,
    mdbCardFooter,
    mdbDatePicker2,
    mdbIcon,
} from "mdbvue";
import AccountDropdown from "@/components/AccountDropdown.vue";
export default {
    name: "GLCoding2",
    components: {
        AccountDropdown,
        PageHeader,
        MultiSelectDropdown,
        draggable,
        GlCodingTour,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardHeader,
        mdbInput,
        mdbBtn,
        mdbCardBody,
        mdbCardFooter,
        mdbDatePicker2,
        mdbIcon,
    },
    props: {
        isAuthenticated: Boolean,
        userInfo: Object,
        options: Object,
        headers: Object,
    },
    filters: {
        pretty: function (value) {
            if (typeof value !== "undefined") {
                return JSON.stringify(JSON.parse(value), null, 2);
            }
        },
    },
    data: function () {
        return {
            compID: this.userInfo.cmpID,
            weburl: this.userInfo,
            baseURL: process.env.VUE_APP_ROOT_API,
            serviceName: "",
            serviceDescription: "",
            isTestMode: false,
            testMessageCenter: [],
            titleObject: {
                pageTitle: "2nd GL Coding",
                pageSubTitle: "Second General Ledger Code Builder",
                iconName: "code-branch", // TODO: Needs to be the equivalent of 'code-branch'
            },
            messageBoxObject: {
                title: "",
                icon: "",
                body: "",
            },
            glCode: "",
            glCodeAlias: "",
            glCodeDesc: "",
            addDescription: false,
            carrierSelection: "-1",
            carrierOptions: [
                {
                    id: -1,
                    value: "-1",
                    text: "Carrier",
                    abbr: "Carrier",
                },
                {
                    id: 0,
                    value: "0",
                    text: "All",
                    abbr: "ALL",
                },
                {
                    id: 1,
                    value: "1",
                    text: "FedEx",
                    abbr: "FDX",
                },
                {
                    id: 2,
                    value: "2",
                    text: "UPS",
                    abbr: "UPS",
                },
                {
                    id: 3,
                    value: "3",
                    text: "DHL Express",
                    abbr: "DHL Ex",
                },
                {
                    id: 4,
                    value: "4",
                    text: "Purolator",
                    abbr: "Pur",
                },
                {
                    id: 5,
                    value: "5",
                    text: "DHL eCommerce",
                    abbr: "DHL eCom",
                },
            ],
            applyRuleRadio: "and",
            optionGroupLabels: [
                {
                    labelKey: "1",
                    label: "Billing Information",
                    optionGroups: [
                        {
                            options: [
                                {
                                    datatype: "dropdown",
                                    value: "f_4_2",
                                    text: "Account Number",
                                    dropdown: "accounts",
                                },
                                { datatype: "string", value: "f_2_1", text: "Invoice Number" },
                                { datatype: "date", value: "f_4_9", text: "Invoice Date" },
                                { datatype: "dropdown", value: "f_4_5", text: "Payor", dropdown: "payors" },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "2",
                    label: "Reference Fields",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "string", value: "f_4_28", text: "Reference 1" },
                                { datatype: "string", value: "f_4_29", text: "Reference 2" },
                                { datatype: "string", value: "f_4_30", text: "Reference 3" },
                                { datatype: "string", value: "f_4_31", text: "Reference 4" },
                                { datatype: "string", value: "f_4_32", text: "Reference 5" },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "3",
                    label: "Shipper Address",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "string", value: "f_4_12", text: "Shipper Name" },
                                { datatype: "string", value: "f_4_13", text: "Shipper Company Name" },
                                { datatype: "string", value: "f_4_14", text: "Shipper Address" },
                                { datatype: "string", value: "f_4_15", text: "Shipper Address 2" },
                                { datatype: "string", value: "f_4_16", text: "Shipper City" },
                                { datatype: "string", value: "f_4_17", text: "Shipper State" },
                                { datatype: "string", value: "f_4_18", text: "Shipper Zipcode" },
                                { datatype: "string", value: "f_4_19", text: "Shipper Country" },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "4",
                    label: "Shipper Address",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "string", value: "f_4_20", text: "Recipient Name" },
                                { datatype: "string", value: "f_4_21", text: "Recipient Company Name" },
                                { datatype: "string", value: "f_4_22", text: "Recipient Address" },
                                { datatype: "string", value: "f_4_23", text: "Recipient Address 2" },
                                { datatype: "string", value: "f_4_24", text: "Recipient City" },
                                { datatype: "string", value: "f_4_25", text: "Recipient State" },
                                { datatype: "string", value: "f_4_26", text: "Recipient Zipcode" },
                                { datatype: "string", value: "f_4_27", text: "Recipient Country" },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "5",
                    label: "Package",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "dropdown", value: "f_4_6", text: "Service Name", dropdown: "services" },
                                { datatype: "date", value: "f_4_10", text: "Transaction Date" },
                                { datatype: "string", value: "f_4_11", text: "Tracking Number" },
                                { datatype: "number", value: "f_4_33", text: "Actual Weight" },
                                {
                                    datatype: "dropdown",
                                    value: "f_4_34",
                                    text: "Actual Weight Unit",
                                    dropdown: "weightUnit",
                                },
                                { datatype: "number", value: "f_4_35", text: "Rated Weight" },
                                {
                                    datatype: "dropdown",
                                    value: "f_4_36",
                                    text: "Rated Weight Unit",
                                    dropdown: "weightUnit",
                                },
                                { datatype: "number", value: "f_4_39", text: "Number of Packages" },
                                { datatype: "string", value: "f_4_40", text: "Zone Code" },
                                { datatype: "number", value: "f_4_41", text: "Customs Value" },
                                { datatype: "dropdown", value: "f_4_42", text: "Customs Unit", dropdown: "currency" },
                                { datatype: "number", value: "f_4_43", text: "Declared Value" },
                                { datatype: "dropdown", value: "f_4_44", text: "Declared Unit", dropdown: "currency" },
                                { datatype: "date", value: "f_4_47", text: "Pickup Date" },
                                { datatype: "date", value: "f_4_48", text: "Delivered Date" },
                                { datatype: "date", value: "f_4_49", text: "Guaranteed Date" },
                                { datatype: "number", value: "f_4_50", text: "Width" },
                                { datatype: "number", value: "f_4_51", text: "Length" },
                                { datatype: "number", value: "f_4_52", text: "Height" },
                                {
                                    datatype: "dropdown",
                                    value: "f_4_64",
                                    text: "Package Type",
                                    dropdown: "packagetype",
                                },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "6",
                    label: "Shipment Information",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "string", value: "f_4_60", text: "Shipment Number" },
                                { datatype: "string", value: "f_4_61", text: "Shipment Reference 1" },
                                { datatype: "string", value: "f_4_62", text: "Shipment Reference 2" },
                            ],
                        },
                    ],
                },
                {
                    labelKey: "7",
                    label: "Package Billing",
                    optionGroups: [
                        {
                            options: [
                                { datatype: "number", value: "f_4_46", text: "Refund" },
                                { datatype: "number", value: "f_4_37", text: "Total Charge" },
                                { datatype: "number", value: "f_4_38", text: "Net Charge" },
                            ],
                        },
                    ],
                },
            ],
            multiSelectedOptions: "",
            multiSelectDataKey: 0,
            multiSelectData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [],
                    },
                ],
            },
            multiSelectAccountData: Object,
            multiSelectServiceData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [],
                    },
                ],
            },
            multiSelectCarrierData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [
                            {
                                id: "1",
                                label: "FedEx",
                                value: "FDX",
                                children: [],
                            },
                            {
                                id: "2",
                                label: "UPS",
                                value: "UPS",
                                children: [],
                            },
                            {
                                id: "3",
                                label: "DHL Express",
                                value: "DHL Ex",
                                children: [],
                            },
                            {
                                id: "4",
                                label: "Purolator",
                                value: "Pur",
                                children: [],
                            },
                            {
                                id: "5",
                                label: "DHL eCommerce",
                                value: "DHL eCom",
                                children: [],
                            },
                        ],
                    },
                ],
            },
            multiSelectPayorsData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [
                            {
                                label: "FedEx",
                                id: null,
                                children: [
                                    {
                                        label: "Shipper",
                                        id: 1,
                                    },
                                    {
                                        label: "Recipient",
                                        id: 2,
                                    },
                                    {
                                        label: "Third party",
                                        id: 10,
                                    },
                                ],
                            },
                            {
                                label: "UPS",
                                id: null,
                                children: [
                                    {
                                        label: "Shipper",
                                        id: 1,
                                    },
                                    {
                                        label: "Recipient",
                                        id: 2,
                                    },
                                    {
                                        label: "Third party Shipper",
                                        id: 12,
                                    },
                                    {
                                        label: "Third party Recipient",
                                        id: 13,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            multiSelectWeightUnitData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [
                            {
                                label: "LB",
                                id: "l",
                                children: [],
                            },
                            {
                                label: "KG",
                                id: "k",
                                children: [],
                            },
                        ],
                    },
                ],
            },
            multiSelectCurrencyData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [
                            {
                                label: "USD",
                                id: "usd",
                                children: [],
                            },
                            {
                                label: "CAD",
                                id: "cad",
                                children: [],
                            },
                        ],
                    },
                ],
            },
            multiSelectPackageTypeData: {
                id: "",
                isValid: true,
                error: null,
                keyvalues: [
                    {
                        $id: "",
                        key: "",
                        value: [
                            {
                                label: "FedEx",
                                id: null,
                                children: [
                                    { label: "10Kg Box", id: "10kg box" },
                                    { label: "25Kg box", id: "25kg box" },
                                    { label: "Box", id: "box" },
                                    { label: "Customer Packaging", id: "customer packaging" },
                                    { label: "Envelope", id: "envelope" },
                                    { label: "Fedex 10Kg box", id: "fedex 10kg box" },
                                    { label: "Fedex 25Kg box", id: "fedex 25kg box" },
                                    { label: "Fedex Box", id: "fedex box" },
                                    { label: "Fedex Envelope", id: "fedex envelope" },
                                    { label: "Fedex Large Box", id: "fedex large box" },
                                    { label: "Fedex Medium Box", id: "fedex medium box" },
                                    { label: "Fedex Pak", id: "fedex pak" },
                                    { label: "Fedex Small Box", id: "fedex small box" },
                                    { label: "Fedex Tube", id: "fedex tube" },
                                    { label: "Fedex X-Large Box", id: "fedex x-large box" },
                                    { label: "Pak", id: "pak" },
                                    { label: "Tube", id: "tube" },
                                ],
                            },
                            {
                                label: "UPS",
                                id: null,
                                children: [
                                    { label: "10Kg Value Box-Documents", id: "10kg value box-documents" },
                                    { label: "10Kg Value Box-Non Documents", id: "10kg value box-non documents" },
                                    { label: "25Kg Value Box-Documents", id: "25kg value box-documents" },
                                    { label: "25Kg Value Box-Non Documents", id: "25kg value box-non documents" },
                                    { label: "Documents", id: "documents" },
                                    { label: "Letter", id: "letter" },
                                    { label: "Package", id: "package" },
                                    { label: "Pak", id: "pak" },
                                    { label: "Pallet", id: "pallet" },
                                    { label: "Unclassified", id: "unclassified" },
                                ],
                            },
                            {
                                label: "DHL",
                                id: null,
                                children: [],
                            },
                            {
                                label: "DHL eCom",
                                id: null,
                                children: [],
                            },
                        ],
                    },
                ],
            },
            multiSelectPackageTypeData1: [
                {
                    label: "FedEx",
                    id: null,
                    children: [
                        { label: "10Kg Box", id: "10kg box" },
                        { label: "25Kg box", id: "25kg box" },
                        { label: "Box", id: "box" },
                        { label: "Customer Packaging", id: "customer packaging" },
                        { label: "Envelope", id: "envelope" },
                        { label: "Fedex 10Kg box", id: "fedex 10kg box" },
                        { label: "Fedex 25Kg box", id: "fedex 25kg box" },
                        { label: "Fedex Box", id: "fedex box" },
                        { label: "Fedex Envelope", id: "fedex envelope" },
                        { label: "Fedex Large Box", id: "fedex large box" },
                        { label: "Fedex Medium Box", id: "fedex medium box" },
                        { label: "Fedex Pak", id: "fedex pak" },
                        { label: "Fedex Small Box", id: "fedex small box" },
                        { label: "Fedex Tube", id: "fedex tube" },
                        { label: "Fedex X-Large Box", id: "fedex x-large box" },
                        { label: "Pak", id: "pak" },
                        { label: "Tube", id: "tube" },
                    ],
                },
                {
                    label: "UPS",
                    id: null,
                    children: [
                        { label: "10Kg Value Box-Documents", id: "10kg value box-documents" },
                        { label: "10Kg Value Box-Non Documents", id: "10kg value box-non documents" },
                        { label: "25Kg Value Box-Documents", id: "25kg value box-documents" },
                        { label: "25Kg Value Box-Non Documents", id: "25kg value box-non documents" },
                        { label: "Documents", id: "documents" },
                        { label: "Letter", id: "letter" },
                        { label: "Package", id: "package" },
                        { label: "Pak", id: "pak" },
                        { label: "Pallet", id: "pallet" },
                        { label: "Unclassified", id: "unclassified" },
                    ],
                },
                {
                    label: "DHL",
                    id: null,
                    children: [],
                },
                {
                    label: "DHL eCom",
                    id: null,
                    children: [],
                },
            ],
            multiSelectOptions: {
                defaultText: "Select Accounts",
                isTransformNeeded: "yes",
            },
            operatorSelectList: {
                operatorToggler: "stringOptions",
                operatorSelection: "0",
                stringOptions: [
                    { numoperand: "1", value: "like", text: "contains" },
                    { numoperand: "1", value: "notlike", text: "does not contain" },
                    { numoperand: "1", value: "similarto", text: "contains any of (A,B,C...)" },
                    { numoperand: "1", value: "notsimilarto", text: "does not contain any of (A,B,C...)" },
                    { numoperand: "1", value: "startswith", text: "starts with" },
                    { numoperand: "1", value: "endswith", text: "ends with" },
                    { numoperand: "1", value: "equals", text: "equals" },
                    { numoperand: "1", value: "notequals", text: "not equal to" },
                    { numoperand: "1", value: "in", text: "is in (A,B,C...)" },
                    { numoperand: "1", value: "notin", text: "is not in (A,B,C...)" },
                    { numoperand: "0", value: "empty", text: "is empty" },
                    { numoperand: "0", value: "notempty", text: "is not empty" },
                    { numoperand: "0", value: "null", text: "is null" },
                    { numoperand: "0", value: "notnull", text: "is not null" },
                ],
                numberOperators: [
                    { numoperand: "1", value: "equals", text: "equals" },
                    { numoperand: "1", value: "notequals", text: "not equal to" },
                    { numoperand: "2", value: "between", text: "between" },
                    { numoperand: "2", value: "notbetween", text: "not between" },
                    { numoperand: "1", value: "less", text: "less than" },
                    { numoperand: "1", value: "lessequal", text: "less or equal to" },
                    { numoperand: "1", value: "greater", text: "greater than" },
                    { numoperand: "1", value: "greaterequal", text: "greater or equal to" },
                    { numoperand: "1", value: "in", text: "in" },
                    { numoperand: "1", value: "notin", text: "is not in" },
                    { numoperand: "0", value: "null", text: "is null" },
                    { numoperand: "0", value: "notnull", text: "is not null" },
                ],
                dateOperators: [
                    { numoperand: "1", value: "equals", text: "equals" },
                    { numoperand: "1", value: "notequals", text: "not equals" },
                    { numoperand: "2", value: "between", text: "between" },
                    { numoperand: "2", value: "notbetween", text: "not between" },
                    { numoperand: "1", value: "before", text: "before" },
                    { numoperand: "1", value: "after", text: "after" },
                    { numoperand: "0", value: "null", text: "is null" },
                    { numoperand: "0", value: "notnull", text: "is not null" },
                ],
            },
            newRulesObj: {},
            addRuleDisplay: [],
            rulesCounter: 0,
            glCodesList: [],
            myGLCodesList: [],
            groupOptionSelected: "0",
            showMultiSelectDropdown: false,
            showOperatorSelectList: true,
            selectedAccountActive: false,
            showStringEquation: true,
            showStringRhs: false,
            showNumberEquation: false,
            showNumberRhs: false,
            showDateEquation: false,
            showDateRhs: false,
            showRhs: false,
            leftHandSide: "",
            rightHandSide: "",
            loadedData: {
                accounts: false,
                services: false,
                payors: false,
                weightUnit: false,
                currency: false,
                packagetype: false,
            },
        };
    },
    computed: {
        premiumServices() {
            return this.services
                ? this.services.map((s) => {
                      if (s.cps_is_active === "1") return s.cps_name;
                  })
                : null;
        },
        glService() {
            this.isLoading = true;
            const glCode = this.services.find((c) => c.cps_name === "glcode");
            if (glCode) {
                this.loadAllGLcodes();
                this.loadAccountsData();
                this.loadServicesData();
            }
            this.isLoading = false;
            return glCode;
        },
    },
    methods: {
        startTour() {
            const steps = [
                {
                    text: document.getElementById("step-one").innerHTML,
                    buttons,
                    title: "GL Coding Tour",
                },
                {
                    attachTo: { element: "#name-select", on: "bottom" },
                    text: document.getElementById("step-two").innerHTML,
                    buttons,
                    title: "Name Code",
                },
                {
                    attachTo: { element: "#carrier-select", on: "bottom" },
                    text: document.getElementById("step-three").innerHTML,
                    buttons,
                    title: "Select Carrier",
                },
                {
                    attachTo: { element: "#any-select", on: "bottom" },
                    text: document.getElementById("step-four").innerHTML,
                    buttons,
                    title: "Any or All",
                },
                {
                    attachTo: { element: "#create-rule", on: "bottom" },
                    text: document.getElementById("step-five").innerHTML,
                    buttons,
                    title: "Create Rule",
                },
                {
                    attachTo: { element: "#save", on: "top" },
                    text: document.getElementById("step-six").innerHTML,
                    buttons,
                    title: "Save GL Code",
                },
                {
                    attachTo: { element: "#my-gl", on: "left" },
                    text: document.getElementById("step-seven").innerHTML,
                    buttons,
                    title: "My GL Codes",
                },
            ];

            const tour = this.$shepherd(getTourDefaults());

            tour.addSteps(steps);

            tour.start();
        },
        makeToast(variant = null) {
            if (variant === "success") {
                this.$notify.success({
                    message: this.messageBoxObject.body,
                    title: this.messageBoxObject.title,
                    variant: variant,
                    solid: true,
                });
            } else {
                this.$notify.error({
                    message: this.messageBoxObject.body,
                    title: this.messageBoxObject.title,
                    variant: variant,
                    solid: true,
                });
            }
        },
        onChangeGroupOptions() {
            this.showMultiSelectDropdown = false;
            this.showOperatorSelectList = true;
            this.resetEquation();
            let dataType = this.groupOptionSelected.datatype || "string";

            if (dataType == "dropdown") {
                let dropdown = this.groupOptionSelected.dropdown;
                this.loadData(dropdown);
            } else if (dataType == "number") {
                this.showNumberEquation = true;
                this.operatorSelectList.operatorToggler = "numberOperators";
            } else if (dataType == "date") {
                this.showDateEquation = true;
                this.operatorSelectList.operatorToggler = "dateOperators";
            } else if (dataType == "string") {
                this.showStringEquation = true;
                this.operatorSelectList.operatorToggler = "stringOptions";
            } else {
                this.showStringEquation = true;
                this.operatorSelectList.operatorToggler = "stringOptions";
            }
        },
        resetEquation() {
            this.showStringEquation = false;
            this.showNumberEquation = false;
            this.showDateEquation = false;
            this.showStringRhs = false;
            this.showNumberRhs = false;
            this.showDateRhs = false;
            this.showRhs = false;
        },
        onChangeOperators(event) {
            this.resetEquation();
            let operatorToggler = this.operatorSelectList.operatorToggler;
            let numOfOperands = this.operatorSelectList.operatorSelection.numoperand;

            if (numOfOperands == 1 || !numOfOperands) {
                if (operatorToggler == "dateOperators") {
                    this.showDateEquation = true;
                } else if (operatorToggler == "numberOperators") {
                    this.showNumberEquation = true;
                } else {
                    this.showStringEquation = true;
                }
            } else if (numOfOperands == 2) {
                this.showRhs = true;
                if (operatorToggler == "dateOperators") {
                    this.showDateEquation = true;
                    this.showDateRhs = true;
                } else if (operatorToggler == "numberOperators") {
                    this.showNumberEquation = true;
                    this.showNumberRhs = true;
                } else {
                    this.showStringEquation = true;
                    this.showStringRhs = true;
                }
            }
            this.addTestMessage("onCvhangeOperators numOfOperands: " + numOfOperands);
        },
        addRule(event) {
            let dataType = this.groupOptionSelected.datatype;
            let fieldId = this.groupOptionSelected.value;
            let fieldName = this.groupOptionSelected.text;
            let operatorName = this.operatorSelectList.operatorSelection.text;
            let numOfOperand = 0;
            let operatorId = this.operatorSelectList.operatorSelection.value;
            let firstValue = this.leftHandSide;
            let secondValue = this.rightHandSide;

            if (this.operatorSelectList.operatorSelection.numoperand) {
                numOfOperand = this.operatorSelectList.operatorSelection.numoperand;
            } else {
                numOfOperand = 0;
            }
            if (fieldId.indexOf("f") < 0) {
                this.messageBoxObject.title = "Error: Selection List";
                this.messageBoxObject.body = "Invalid option has been selected ( " + fieldId + ")";
                this.makeToast("danger");
                return;
            }

            let rule = fieldName;
            if (dataType === "dropdown") {
                operatorId = "in";
                rule += " IN (";
                const selectedValues = this.multiSelectedOptions;
                rule += selectedValues.substring(0, 30);
                if (selectedValues.length > 30) rule += "...";
                rule += ")";
            } else if (numOfOperand == 0) {
                rule += " " + operatorName;
            } else if (numOfOperand == 1) {
                let val = (firstValue = this.leftHandSide);
                if (!val || val == "") return;

                rule += " " + operatorName + " " + val;
            } else if (numOfOperand == 2) {
                let val1 = firstValue;
                let val2 = secondValue;

                if (!val1 || val1 == "" || !val2 || val2 == "") return;

                rule += " " + operatorName + " " + val1 + " and " + val2;
            }

            if (rule == fieldName) return;

            let id = "r" + this.rulesCounter++;
            let obj = {
                field: fieldId,
                dataType: dataType,
                op: operatorId,
                firstValue: firstValue,
                secondValue: secondValue,
            };
            let ruleDisplayObj = {
                id: id,
                rule: rule,
            };

            this.newRulesObj[id] = obj;
            this.addRuleDisplay.push(ruleDisplayObj);

            // Reset screen elements
            this.resetRuleElements();
        },
        deleteRule(ruleId, ruleIndex) {
            delete this.newRulesObj[ruleId];
            this.addRuleDisplay.splice(ruleIndex, 1);
        },
        resetRuleElements() {
            this.groupOptionSelected = "0";
            this.operatorSelectList.operatorToggler = "stringOptions";
            this.operatorSelectList.operatorSelection = "0";
            this.showMultiSelectDropdown = false;
            this.showOperatorSelectList = true;
            this.showStringEquation = true;
            this.showNumberEquation = false;
            this.showDateEquation = false;
            this.showStringRhs = false;
            this.showNumberRhs = false;
            this.showDateRhs = false;
            this.showRhs = false;
            this.leftHandSide = "";
            this.rightHandSide = "";
        },
        resetScreenElements() {
            this.carrierSelection = "-1";
            this.glCode = "";
            this.glCodeAlias = "";
            this.glCodeDesc = "";
            this.groupOptionSelected = "0";
            this.operatorSelectList.operatorToggler = "stringOptions";
            this.operatorSelectList.operatorSelection = "0";
            this.showMultiSelectDropdown = false;
            this.showOperatorSelectList = true;
            this.leftHandSide = "";
            this.rightHandSide = "";
        },
        loadData(dropdown) {
            if (dropdown == "payors") {
                this.multiSelectOptions.defaultText = "Select Payor";
                this.multiSelectOptions.isTransformNeeded = "no";
                this.multiSelectData = this.multiSelectPayorsData;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 2;
            }

            if (dropdown == "accounts") {
                this.multiSelectOptions.defaultText = "Select Accounts";
                this.multiSelectOptions.isTransformNeeded = "yes";
                // Refresh Accounts Data with API call
                this.loadAccountsData();
                this.multiSelectData = this.multiSelectAccountData;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 1;
            }

            if (dropdown == "services") {
                this.multiSelectOptions.defaultText = "Select Services";
                this.multiSelectOptions.isTransformNeeded = "no";
                // TODO: loadServicesData pushes a double layer of keyvalues, needs fixed
                this.multiSelectData.keyvalues = this.multiSelectServiceData.keyvalues;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 2;
            }

            if (dropdown == "weightUnit") {
                this.multiSelectOptions.defaultText = "Select Weight";
                this.multiSelectOptions.isTransformNeeded = "no";
                this.multiSelectData = this.multiSelectWeightUnitData;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 2;
            }

            if (dropdown == "currency") {
                this.multiSelectOptions.defaultText = "Select Weight";
                this.multiSelectOptions.isTransformNeeded = "no";
                this.multiSelectData = this.multiSelectCurrencyData;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 2;
            }

            if (dropdown == "packagetype") {
                this.multiSelectOptions.defaultText = "Select Package Type";
                this.multiSelectOptions.isTransformNeeded = "no";
                this.multiSelectData = this.multiSelectPackageTypeData;
                this.showOperatorSelectList = false;
                this.showMultiSelectDropdown = true;
                this.multiSelectDataKey = 2;
            }
            this.loadedData[dropdown] = true;
        },
        collectSelectedOptions(options) {
            // TODO: fix duplicates in multiselectdropdown *or* push unique values
            let selectedOptions = "";
            for (const option of options) {
                if (option.selected) {
                    selectedOptions += "," + option.label + ",";
                }
                if (option.children) {
                    for (const child of option.children) {
                        if (child.selected) {
                            selectedOptions += "," + child.label + ",";
                        }
                        if (child.children) {
                            for (const grandchild of child.children) {
                                if (grandchild.selected) {
                                    selectedOptions += "," + grandchild.label + ",";
                                }
                            }
                        }
                    }
                }
            }
            this.multiSelectedOptions = selectedOptions.replace(/(^,)|(,$)/g, "");
        },
        updateSelectedAccount(userAccounts, accountValues) {
            for (let i = 0; i < accountValues.length; i++) {
                accountValues[i] = parseInt(accountValues[i]);
            }
            this.userAccounts = accountValues;
            this.multiSelectedOptions = this.userAccounts.toString();
            if (!this.userAccounts.length) {
                this.userAccounts = null;
            }
        },
        updateSelectedActive(val) {
            this.selectedAccountActive = val;
        },
        async loadAllGLcodes() {
            let url =
                this.baseURL +
                `/glcode2?glcode2_cmp_id=${this.userInfo.cmpID}&sortColumn=glcode2_order&glcode2_is_active=true`;
            await this.$http
                .get(url, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.myGLCodesList = response.data;
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ message: "ERROR LoadAllGLCodes: " + error, title: "Error" });
                });
        },
        async loadAccountsData() {
            let url = this.baseURL + "/user";
            let paramData = { action: "getAccountTree", userId: this.userInfo.usrId, cmpId: this.userInfo.cmpID };

            await this.$http
                .post(url, paramData, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.multiSelectAccountData = response.data;
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ message: "ERROR loadAccountsData: " + error });
                });
        },
        async saveGLCode() {
            let name = this.glCode;
            let alias = this.glCodeAlias;
            let desc = this.glCodeDesc;
            let carrier = this.carrierSelection;
            this.addTestMessage("Carrier: " + carrier);

            if (!name || name.length < 1) {
                this.messageBoxObject.title = "Name Needed";
                this.messageBoxObject.body = "You should enter a name for this GL Code";
                this.messageBoxObject.isModalVisible = true;
                this.makeToast("danger");
                return;
            }

            if (carrier < 0) {
                this.messageBoxObject.title = "Carrier Needed";
                this.messageBoxObject.body = "You should select a carrier for this GL Code";
                this.messageBoxObject.isModalVisible = true;
                this.makeToast("danger");
                return;
            }

            if (Object.keys(this.newRulesObj).length < 1) {
                this.messageBoxObject.title = "Rule Needed";
                this.messageBoxObject.body = "You should add at least one rule to this GL Code";
                this.messageBoxObject.isModalVisible = true;
                this.makeToast("danger");
                return;
            }

            let saveGLCodeConfirm = window.confirm(
                "Are you sure you are done adding rules for " + name + " and would like to save?"
            );
            if (!saveGLCodeConfirm) return;

            const rulesArray = [];
            for (let prop in this.newRulesObj) {
                if (this.newRulesObj.hasOwnProperty(prop)) rulesArray.push(this.newRulesObj[prop]);
            }
            let url = this.baseURL + "/glcode2";
            let paramData = {
                glcode2_cmp_id: this.userInfo.cmpID,
                glcode2_name: name,
                glcode2_alias: alias,
                glcode2_desc: desc,
                glcode2_car_id: carrier,
                glcode2_is_active: true, // TODO: $("input#glcodeActive").is(':checked')
                glcode2_conditions_operator: this.applyRuleRadio, // TODO: $("input[name='andor']:checked").val()
                rules: rulesArray,
            };

            await this.$http
                .post(url, paramData, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.messageBoxObject.title = "Success: Rule Saved";
                        this.messageBoxObject.body = "Rule has been loaded";
                        this.makeToast("success");
                        this.newRulesObj = [];
                        this.glCode = "";
                        this.glCodeAlias = "";
                        this.glCodeDesc = "";
                        this.carrierSelection = "-1";
                        this.loadAllGLcodes();
                        this.resetScreenElements();
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ message: "ERROR loadAccountsData: " + error });
                });
        },
        async deleteGLCode(element) {
            let glCodeId = element.glcode2_id;
            let url = this.baseURL + "/glcode2/" + glCodeId;

            await this.$http
                .delete(url, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.messageBoxObject.title = "Success: Rule Deleted";
                        this.messageBoxObject.body = "Rule has been deleted";
                        this.makeToast("success");
                        this.loadAllGLcodes();
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ message: "ERROR loadAccountsData: " + error });
                });
        },
        async savePriority() {
            let url = this.baseURL + "/glcode2/1";
            let glCodeIdsList = this.myGLCodesList.map((a) => a.glcode2_id);
            let paramData = { ids: glCodeIdsList, cmpId: this.userInfo.cmpID };
            await this.$http
                .put(url, paramData, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.messageBoxObject.title = "Success: Rule Priority";
                        this.messageBoxObject.body = "Rules priority has been saved";
                        this.makeToast("success");
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ message: "ERROR loadAccountsData: " + error });
                });
        },
        loadServicesData: async function () {
            let url = this.baseURL + "/service";

            await this.$http
                .get(url, {
                    headers: this.headers,
                })
                .then((response) => {
                    if (response.data) {
                        this.glCodesList = response.data;
                        const services = [
                            {
                                label: "FedEx",
                                id: null,
                                children: [
                                    {
                                        label: "Ground",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "Air",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "International",
                                        id: null,
                                        children: [],
                                    },
                                ],
                            },
                            {
                                label: "UPS",
                                id: null,
                                children: [
                                    {
                                        label: "Ground",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "Air",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "International",
                                        id: null,
                                        children: [],
                                    },
                                ],
                            },
                            {
                                label: "DHL",
                                id: null,
                                children: [
                                    {
                                        label: "Ground",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "Air",
                                        id: null,
                                        children: [],
                                    },
                                    {
                                        label: "International",
                                        id: null,
                                        children: [],
                                    },
                                ],
                            },
                        ];
                        for (const idx in response.data) {
                            const item = response.data[idx];
                            if (item.srv_type && item.srv_type !== "") {
                                if (item.srv_type == "air") {
                                    let type = 0;
                                    if (item.srv_type == "air") type = 1;
                                    else if (item.srv_type == "international") type = 2;

                                    services[item.srv_car_id - 1].children[type].children.push({
                                        label: item.srv_name.toUpperCase(),
                                        id: item.srv_id,
                                    });
                                }
                            }
                        }
                        this.multiSelectServiceData.keyvalues[0].value.push(services[0]);
                    }
                })
                .catch(function (error) {
                    this.$notify.error({ error: "ERROR loadServicesData: " + error });
                });
        },
        addTestMessage(testMessage) {
            this.testMessageCenter.push(testMessage);
        },
    },
};
</script>

<style>
.testmessagecenter {
    height: 50px;
    overflow: auto;
}
.adddesc {
    cursor: pointer;
    padding: 10px 0 0 10px;
}
.ruledisplay {
    overflow-y: scroll;
    height: 400px;
    margin-bottom: 0;
    padding: 10px 0 0 0;
}
.glruletext {
    border: 1px solid #ddd;
    padding-top: 6px;
    height: 33px;
    margin: 0 0 5px 0;
    background-image: url(../../img/bkg_noise400.jpg);
}
.deleterule {
    float: right;
    padding: 0 20px 0 0;
    cursor: pointer;
}
.removeCol {
    float: right;
}
.dragRowParent {
    overflow-y: scroll;
    height: 585px;
    padding: 5px;
    /*background-image: url(../../img/textures/subtle_dots.png);*/
    margin-bottom: 0;
}
.dragRow {
    font-size: 14px;
    border: 1px solid #ddd;
    background-image: url(../../img/bkg_noise400.jpg);
    border-radius: 4px;
    padding: 5px;
}
.dragRowGLCode {
    font-size: 18px;
}
.dragRowField {
    font-size: 14px;
    padding: 0 2px 0 2px;
    font-weight: bold;
}
.dragRowFieldLabel {
    padding-left: 5px;
}
</style>
